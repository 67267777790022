import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import DataTable from "src/components/DataTable";

const PopulateComparisonGroups = ({ comparison }) => {
  const [groups, setGroups] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const navigate = useNavigate();

  const updateComparisonGroups = async (event) => {
    event.preventDefault();

    try {
      const requests = groups.map((group) =>
        apiRequest(`comparisongroup/${group.id}`, {
          method: "PATCH",
          body: JSON.stringify({
            scoringruns: group.scoringruns,
          }),
        }),
      );

      // Execute all PATCH requests concurrently
      const responses = await Promise.all(requests);

      // Check for errors in the responses
      for (const response of responses) {
        if (!response.ok) {
          throw new Error(`Failed to update a groups: ${response.statusText}`);
        }
      }

      toast.success("Groups updated successfully!");

      // navigate from wizard, because you are done. For now, use home page, later use comparison index.
      navigate(`/comparisonreport/${comparison.id}`);
    } catch (error) {
      console.error("Error creating groups:", error);
      toast.error("An error occurred while creating groups.");
    }
  };

  const addToGroup = (scoringRunId, groupId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id.toString() === groupId.toString()
          ? {
              ...group,
              scoringruns: [...(group.scoringruns || []), scoringRunId],
            }
          : group,
      ),
    );

    // Keep the selected option unchanged when adding
    setSelectedOptions((prev) => ({
      ...prev,
      [scoringRunId]: groupId,
    }));
  };

  const removeFromGroup = (scoringRunId, groupId) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id.toString() === groupId.toString()
          ? {
              ...group,
              scoringruns: group.scoringruns?.filter(
                (id) => id !== scoringRunId,
              ),
            }
          : group,
      ),
    );

    // Reset the select for the given row ID when removing
    setSelectedOptions((prev) => ({
      ...prev,
      [scoringRunId]: "default",
    }));
  };

  const columns = [
    {
      Header: "Description",
      render: (row) => <span>{row.files[0]?.study?.description}</span>,
      filterKey: "study_description_contains",
    },
    {
      Header: "Scoring Run",
      accessor: "id",
      filterKey: "id_contains",
    },
    {
      Header: "EDF Date",
      render: (row) => (
        <span>
          {
            row.files.find((file) => file.type === "Raw EDF")?.file_metadata
              ?.edf_header?.start_time
          }
        </span>
      ),
    },
    {
      Header: "",
      render: (row) => {
        const isDisabled = groups.some((group) =>
          group.scoringruns?.includes(row.id),
        );

        return (
          <div>
            {row.status === "Reporting Complete" && (
              <Form.Select
                value={selectedOptions[row.id] || "default"} // Set the current value
                onChange={(e) => {
                  const groupId = e.target.value;
                  if (groupId !== "default") {
                    addToGroup(row.id, groupId);
                  }
                }}
                disabled={isDisabled}
              >
                <option value="default">Select Group...</option>
                {groups.map((group) => {
                  return (
                    <option value={group.id} key={group.id}>
                      {group.name}
                    </option>
                  );
                })}
              </Form.Select>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (comparison) setGroups(comparison.comparison_group_list);
  }, [comparison]); // Add comparison as a dependency

  return (
    <Stack gap={4} className="body">
      <h1>Populate Groups</h1>
      <Form onSubmit={updateComparisonGroups}>
        <Stack gap={3}>
          <Row xs={1} md={2} xxl={4} className="g-4">
            {groups.map((group, groupIndex) => (
              <Col key={group.id}>
                <div
                  className={`data-card group-card group-card-${groupIndex} h-100`}
                >
                  <h5>{group.name}</h5>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="flex flex-wrap"
                  >
                    {group.scoringruns &&
                      group.scoringruns.map((id) => (
                        <div
                          className="data-card p-2 comp-group-item-card"
                          key={id}
                        >
                          {id}
                          <Button
                            className="remove-item-button"
                            variant="danger"
                            onClick={() => {
                              removeFromGroup(id, group.id);
                            }}
                          >
                            <FontAwesomeIcon icon="times" />
                          </Button>
                        </div>
                      ))}
                  </Stack>
                </div>
              </Col>
            ))}
          </Row>

          <DataTable
            endpoint="scoringrun"
            columns={columns}
            itemsPerPage={10}
          />
          <Button variant="secondary" type="submit">
            Submit
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export default PopulateComparisonGroups;
