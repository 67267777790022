import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  Stack,
  Table,
} from "react-bootstrap";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import ChannelMappingTable from "src/components/ChannelMappings/ChannelMappingTable";
import PositionTable from "src/components/ChannelMappings/PositionTable";
import DataTable from "src/components/DataTable";
import TextWithTooltip from "src/components/TextWithTooltip";
import { useUser } from "src/UserContext";

const ScoringRunTable = forwardRef(({ studyId = null }, ref) => {
  const { cerebraUser } = useUser();
  const [isLoadingFile, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState("");
  const [currentChannelMapping, setCurrentChannelMapping] = useState(null);
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showScoringSettingsModal, setShowScoringSettingsModal] =
    useState(false);
  const [currentScoringRun, setCurrentScoringRun] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState("60");
  const [refreshKey, setRefreshKey] = useState(0);

  // Used for dropdown
  const ellipsisContainerRef = useRef(document.body);
  const orpContainerRef = useRef(document.body);
  const [ellipsisMenuElement, setEllipsisMenuElement] = useState(null);
  const [orpMenuElement, setOrpMenuElement] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshData: triggerRefresh,
  }));

  const triggerRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1); // This will change the key and trigger refresh
  };

  useEffect(() => {
    let intervalId;
    if (refreshInterval) {
      triggerRefresh();
      intervalId = setInterval(triggerRefresh, Number(refreshInterval) * 1000);
    }
    return () => clearInterval(intervalId);
  }, [refreshInterval]);

  const getFileFromList = (files, fileType) => {
    return files.find((file) => file.type === fileType);
  };

  const downloadFile = (fileId, fileName, url = `file/${fileId}/download`) => {
    setIsLoading(true);
    setIsLoadingButton(fileName);
    apiRequest(url, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a link element
        const downloadLink = document.createElement("a");
        // Set the href attribute with the blob data
        downloadLink.href = window.URL.createObjectURL(blob);
        // Set the download attribute with the desired file name
        downloadLink.download = fileName;
        // Append the link to the document
        document.body.appendChild(downloadLink);
        // Trigger a click on the link to start the download
        downloadLink.click();
        // Remove the link from the document
        document.body.removeChild(downloadLink);
        setIsLoading(false);
        setIsLoadingButton("");
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  const deleteScoringRun = (id) => {
    apiRequest(`scoringrun/${id}`, {
      method: "DELETE",
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error(
            "You do not have permission to delete this Channel Mapping",
          );
        } else if (response.status === 400) {
          console.log(response);
          toast.error(
            `The Scoring Run you have selected (${id}}) is not in the Created stage and cannot be deleted`,
          );
        } else if (response.ok) {
          toast.success("Scoring Run successfully deleted!");
          // update list after delete
          triggerRefresh();
        } else {
          console.log(response);
          toast.error("An error occurred while deleting this scoring run.");
        }
        return response.json();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const statusCell = (scoringRun) => {
    if (
      ["Validation Error", "Scoring Error", "Reporting Error"].includes(
        scoringRun.status,
      ) &&
      scoringRun.status_message
    ) {
      return (
        <div>
          <TextWithTooltip
            text={scoringRun.status}
            tooltip={scoringRun.status_message}
          />
        </div>
      );
    } else {
      return (
        <div className="flex justify-content-between">
          {scoringRun.status}
          {scoringRun.status === "Created" && (
            <Button
              variant="danger"
              id={`delete_${scoringRun.id}`}
              onClick={() => deleteScoringRun(scoringRun.id)}
              size="small"
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          )}
        </div>
      );
    }
  };

  const updateScoringSettingsCell = (scoringRun, studyId) => {
    if (
      [
        "Scoring in Progress",
        "Scoring Complete",
        "Scoring Error",
        "Reporting Complete",
        "Reporting Error",
      ].includes(scoringRun.status)
    ) {
      return (
        <Button
          variant="link"
          onClick={() => handleScoringSettingsShow(scoringRun)}
        >
          View
        </Button>
      );
    }
    return (
      <Button
        variant="outline-primary"
        href={`/updatescoringsettings/${scoringRun.id}/${studyId}`}
      >
        Update
      </Button>
    );
  };

  const attachChannelMappingCell = (scoringRun, canAttachChannelMapping) => {
    if (canAttachChannelMapping) {
      if (!scoringRun.channelmapping) {
        return (
          <Button
            variant="outline-primary"
            href={`/attachchannelmapping/${scoringRun.id}`}
          >
            Attach
          </Button>
        );
      } else {
        return (
          <Button variant="link" onClick={() => handleChannelShow(scoringRun)}>
            View
          </Button>
        );
      }
    }
  };

  const handleChannelClose = () => {
    setShowChannelModal(false);
    setCurrentChannelMapping(null);
  };

  const handleChannelShow = (scoringRun) => {
    setCurrentChannelMapping(scoringRun.channelmapping);
    setShowChannelModal(true);
  };

  const handleScoringSettingsClose = () => {
    setShowScoringSettingsModal(false);
  };

  const handleScoringSettingsShow = (scoringRun) => {
    setCurrentScoringRun(scoringRun);
    setShowScoringSettingsModal(true);
  };

  const verticalEllipsisToggle = forwardRef(({ onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FontAwesomeIcon icon="ellipsis-v" />
    </a>
  ));
  verticalEllipsisToggle.displayName = "verticalEllipsisToggle";

  const viewOrpReportCell = (scoringRun) => {
    if (scoringRun.status === "Reporting Complete") {
      return (
        <div>
          <Dropdown as={ButtonGroup}>
            <Button
              variant="outline-primary"
              href={`/orpreport/${scoringRun.id}`}
              type="button"
            >
              View
            </Button>

            {cerebraUser.role !== "ORPUser" && (
              <Dropdown.Toggle
                split
                variant="outline-primary"
                id="dropdown-split-basic"
              />
            )}

            {cerebraUser.role !== "ORPUser" &&
              orpMenuElement &&
              ReactDOM.createPortal(
                <Dropdown.Menu>
                  <Dropdown.Item href={`/orpreport/${scoringRun.id}`}>
                    Simple ORP Report
                  </Dropdown.Item>
                  <Dropdown.Item href={`/orpreportdetailed/${scoringRun.id}`}>
                    Detailed ORP Report
                  </Dropdown.Item>
                </Dropdown.Menu>,
                orpMenuElement,
              )}
          </Dropdown>
        </div>
      );
    }
  };

  const fileDownloadDropdown = (
    runId,
    rawEdf,
    resampledEdf,
    autoscoringEvents,
    reportFile,
    externalScoringFile,
  ) => {
    if (
      !rawEdf &&
      !resampledEdf &&
      !autoscoringEvents &&
      !reportFile &&
      !externalScoringFile
    ) {
      return null;
    }
    return (
      <Dropdown autoClose="outside">
        <Dropdown.Toggle as={verticalEllipsisToggle} />
        {ellipsisMenuElement &&
          ReactDOM.createPortal(
            <Dropdown.Menu>
              {!!rawEdf && (
                <Dropdown.Item
                  id={`${rawEdf.id}_button`}
                  onClick={() =>
                    downloadFile(rawEdf.id, `raw_edf_${runId}.edf`)
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Raw EDF</span>
                  {isLoadingFile &&
                    isLoadingButton === `raw_edf_${runId}.edf` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!externalScoringFile && (
                <Dropdown.Item
                  id={`${externalScoringFile.id}_button`}
                  onClick={() =>
                    downloadFile(
                      externalScoringFile.id,
                      `external_scoring_${runId}.txt`,
                    )
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">External Scoring File</span>
                  {isLoadingFile &&
                    isLoadingButton === `external_scoring_${runId}.txt` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!resampledEdf && (
                <Dropdown.Item
                  id={`${resampledEdf.id}_button`}
                  onClick={() =>
                    downloadFile(resampledEdf.id, `resampled_edf_${runId}.edf`)
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Resampled EDF</span>
                  {isLoadingFile &&
                    isLoadingButton === `resampled_edf_${runId}.edf` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!autoscoringEvents && cerebraUser.role !== "ORPUser" && (
                <Dropdown.Item
                  id={`${autoscoringEvents.id}_button`}
                  onClick={() =>
                    downloadFile(
                      autoscoringEvents.id,
                      `autoscoring_events_${runId}.jsonevents`,
                    )
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Autoscoring Events</span>
                  {isLoadingFile &&
                    isLoadingButton ===
                      `autoscoring_events_${runId}.jsonevents` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!reportFile && cerebraUser.role !== "ORPUser" && (
                <Dropdown.Item
                  id={`${reportFile.id}_button`}
                  onClick={() =>
                    downloadFile(reportFile.id, `report_${runId}.json`)
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Report Data</span>
                  {isLoadingFile &&
                    isLoadingButton === `report_${runId}.json` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!reportFile && (
                <Dropdown.Item
                  id={`${reportFile.id}_simple_button`}
                  onClick={() =>
                    downloadFile(
                      reportFile.id,
                      `simple_orp_report_${runId}.json`,
                      `scoringrun/${runId}/orpreportsimple`,
                    )
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Simple ORP Report</span>
                  {isLoadingFile &&
                    isLoadingButton === `simple_orp_report_${runId}.json` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
              {!!reportFile && cerebraUser.role !== "ORPUser" && (
                <Dropdown.Item
                  id={`${reportFile.id}_detailed_button`}
                  onClick={() =>
                    downloadFile(
                      reportFile.id,
                      `detailed_orp_report_${runId}.json`,
                      `scoringrun/${runId}/orpreportdetailed`,
                    )
                  }
                  disabled={isLoadingFile}
                >
                  <FontAwesomeIcon icon="download" />
                  <span className="mx-2">Detailed ORP Report</span>
                  {isLoadingFile &&
                    isLoadingButton === `detailed_orp_report_${runId}.json` && (
                      <div
                        className="spinner-border button-spinner"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>,
            ellipsisMenuElement,
          )}
      </Dropdown>
    );
  };

  useEffect(() => {
    if (ellipsisContainerRef.current) {
      setEllipsisMenuElement(ellipsisContainerRef.current);
    }
  }, []);

  useEffect(() => {
    if (orpContainerRef.current) {
      setOrpMenuElement(orpContainerRef.current);
    }
  }, []);

  const columns = [
    ...(!studyId
      ? [
          {
            Header: "Study",
            render: (row) => <span>{row.files[0]?.study?.id}</span>,
            filterKey: "study_id_contains",
          },
          {
            Header: "Description",
            render: (row) => <span>{row.files[0]?.study?.description}</span>,
            filterKey: "study_description_contains",
          },
        ]
      : []),
    {
      Header: "Scoring Run",
      accessor: "id",
      filterKey: "id_contains",
    },
    {
      Header: "Status",
      accessor: "status",
      render: (row) => <span>{statusCell(row)}</span>,
    },
    { Header: "Date Modified", accessor: "date_modified" },
    {
      Header: "EDF Date",
      render: (row) => (
        <span>
          {
            row.files.find((file) => file.type === "Raw EDF")?.file_metadata
              ?.edf_header?.start_time
          }
        </span>
      ),
    },
    {
      Header: "Scoring Settings",
      render: (row) => {
        return (
          <span>{updateScoringSettingsCell(row, row.files[0]?.study?.id)}</span>
        );
      },
    },
    {
      Header: "Channel Mapping",
      render: (row) => {
        const canAttachChannelMapping =
          getFileFromList(row.files, "Raw EDF") &&
          (!row.channelMapping || row.channelMapping.length > 0);
        return (
          <span>{attachChannelMappingCell(row, canAttachChannelMapping)}</span>
        );
      },
    },
    {
      Header: "ORP Report",
      render: (row) => {
        return <span>{viewOrpReportCell(row)}</span>;
      },
    },
    {
      Header: "Files",
      render: (row) => {
        const rawEdf = getFileFromList(row.files, "Raw EDF");
        const resampledEdf = getFileFromList(row.files, "Resampled EDF");
        const externalScoringFile = getFileFromList(
          row.files,
          "External Scoring",
        );
        const autoscoringEvents = getFileFromList(
          row.files,
          "Autoscoring Events",
        );
        const reportFile = getFileFromList(row.files, "Report Data");
        return (
          <span>
            {fileDownloadDropdown(
              row.id,
              rawEdf,
              resampledEdf,
              autoscoringEvents,
              reportFile,
              externalScoringFile,
            )}
          </span>
        );
      },
    },
  ];

  return (
    <Stack gap={4}>
      <Stack direction="horizontal" gap={3}>
        <h3>Scoring Runs</h3>
        <Dropdown as={ButtonGroup} onSelect={setRefreshInterval}>
          <Button onClick={triggerRefresh}>
            <FontAwesomeIcon icon="sync-alt" />
          </Button>
          <Dropdown.Toggle split />
          <Dropdown.Menu>
            <Dropdown.Header>Auto Refresh</Dropdown.Header>
            {[null, "10", "30", "60"].map((interval) => (
              <Dropdown.Item
                key={interval}
                eventKey={interval}
                active={refreshInterval === interval}
              >
                {interval === null ? "Off" : `${interval} seconds`}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
      <DataTable
        endpoint="scoringrun"
        columns={columns}
        itemsPerPage={10}
        urlSearchParams={{
          ...(studyId && { study_id: studyId }),
        }}
        refreshKey={refreshKey}
      />
      <Modal
        size="lg"
        scrollable
        show={showChannelModal}
        onHide={handleChannelClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Channel Mapping</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChannelMappingTable channelMapping={currentChannelMapping} />
          <PositionTable channelMapping={currentChannelMapping} />
        </Modal.Body>
      </Modal>
      <Modal
        size="sm"
        scrollable
        show={showScoringSettingsModal}
        onHide={handleScoringSettingsClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Scoring Settings</Modal.Title>
        </Modal.Header>
        {currentScoringRun && (
          <Modal.Body>
            <Table size="sm" borderless>
              <tbody>
                <tr>
                  <th scope="row">Hypopnea Criteria:</th>
                  <td>{currentScoringRun.hypopnea_criteria}</td>
                </tr>
                <tr>
                  <th scope="row">Prefiltered:</th>
                  <td>{currentScoringRun.prefiltered === 1 ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <th scope="row">Lights On Epoch:</th>
                  <td>{currentScoringRun.lights_on_epoch}</td>
                </tr>
                <tr>
                  <th scope="row">Lights Off Epoch:</th>
                  <td>{currentScoringRun.lights_off_epoch}</td>
                </tr>
                <tr>
                  <th scope="row">Collection System:</th>
                  <td>{currentScoringRun.collection_system}</td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
        )}
      </Modal>
    </Stack>
  );
});
ScoringRunTable.displayName = "ScoringRunTable";

export default ScoringRunTable;
