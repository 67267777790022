import { useEffect, useState } from "react";
import { Col, Dropdown, Stack, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const SitesShow = () => {
  const { id } = useParams();
  const [site, setSite] = useState();
  const [isLoadingSite, setIsLoadingSite] = useState(true);

  const getSite = () => {
    apiRequest(`site/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setSite(data);
        setIsLoadingSite(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSite();
  }, []);

  if (isLoadingSite) {
    return <PageLoading />;
  }

  if (!isLoadingSite && !site) {
    return <div>Site not found</div>;
  }

  return (
    <Stack gap={4} className="body">
      <div className="flex align-items-center justify-content-between me-5">
        <h1>Site ID: {site.id}</h1>

        <Dropdown>
          <Dropdown.Toggle variant="outline-primary">Actions</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href={`/editsite/${id}`}>Edit</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex flex-wrap justify-content-between align-items-start">
        <Col className="data-card">
          <Table size="sm" borderless>
            <tbody>
              <tr>
                <th scope="row">Name:</th>
                <td>{site.name}</td>
              </tr>
              <tr>
                <th scope="row">Active:</th>
                <td>{site.is_active ? "Yes" : "No"}</td>
              </tr>
              <tr>
                <th scope="row">Created At:</th>
                <td>{site.date_created}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </div>
    </Stack>
  );
};

export default SitesShow;
