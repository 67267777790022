import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import DragDropFile from "src/components/DragDropFile";
import PageLoading from "src/components/PageLoading";
import TextWithTooltip from "src/components/TextWithTooltip";

const UpdateScoringSettings = ({
  id: propId,
  setScoringSettingsUpdated,
  studyId: propStudyId,
}) => {
  const { id: urlId, studyId: urlStudyId } = useParams();
  const id = propId || urlId;
  const studyId = propStudyId || urlStudyId;
  const navigate = useNavigate();
  const [isLoadingScoringRun, setIsLoadingScoringRun] = useState(true);
  const [hypopneaCriteria, setHypopneaCriteria] = useState("B");
  const [prefilter, setPrefilter] = useState("0");
  const [lightsOffEpoch, setLightsOffEpoch] = useState("1");
  const [lightsOnEpoch, setLightsOnEpoch] = useState("-1");
  const [collectionSystem, setCollectionSystem] = useState("");
  const [thirdPartyScoringToggle, setThirdPartyScoringToggle] = useState(false);
  const [thirdPartyScoringUploaded, setThirdPartyScoringUploaded] =
    useState(false);

  const getScoringRun = () => {
    apiRequest(`scoringrun/${id}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setHypopneaCriteria(String(data.hypopnea_criteria));
        setPrefilter(String(data.prefilter));
        setLightsOffEpoch(String(data.lights_off_epoch));
        setLightsOnEpoch(String(data.lights_on_epoch));
        if (data.collection_system) {
          setCollectionSystem(String(data.collection_system));
        }
        if (
          data.files &&
          data.files.some((file) => file.type === "External Scoring")
        ) {
          setThirdPartyScoringUploaded(true);
          setThirdPartyScoringToggle(true);
        }
        setIsLoadingScoringRun(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateScoringSettings = (event) => {
    event.preventDefault();

    apiRequest(`scoringrun/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        prefilter,
        collection_system: collectionSystem || null,
        uploaded_by_api: false,
        ...(!thirdPartyScoringToggle && {
          hypopnea_criteria: hypopneaCriteria,
          lights_off_epoch: lightsOffEpoch,
          lights_on_epoch: lightsOnEpoch,
        }),
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this scoring run.");
        } else if (response.ok) {
          // Handle success
          toast.success("Scoring Settings Updated Successfully!");
          if (setScoringSettingsUpdated) setScoringSettingsUpdated(true);
          else navigate(-1);
        } else {
          // Handle other errors
          toast.error("An error occurred while updating this scoring run.");
        }
      })
      .catch((error) => {
        toast.error("An error occured while updating this scoring run.");
        console.error(error);
      });
  };

  useEffect(() => {
    if (id) getScoringRun();
  }, [id]);

  if (isLoadingScoringRun) {
    return <PageLoading />;
  }

  const dragDropExternalFileResponse = {
    description: `External File for ${studyId}`,
    study_id: studyId,
    type: "External Scoring",
    scoringrun_id: id,
  };

  return (
    <Stack gap={4} className="body">
      <h1>Update Scoring Settings</h1>
      <TextWithTooltip
        text={
          <Form.Check
            type="switch"
            id="thirdPartyScoring"
            label="Manual Scoring/Events File"
            checked={thirdPartyScoringToggle}
            disabled={thirdPartyScoringUploaded}
            onChange={() => {
              setThirdPartyScoringToggle(!thirdPartyScoringToggle);
            }}
          />
        }
        tooltip="The sleep staging, AHI, and PLM values shown in the Detailed ORP Report can be derived from a source RML file from the Alice Sleepware G3 software or from and XML file from Compumedics.  Please see the Data Requirements section of the software manual for more details."
        position="bottom"
      />

      {thirdPartyScoringToggle &&
        (!thirdPartyScoringUploaded ? (
          <DragDropFile
            onUploadSuccess={() => {
              setThirdPartyScoringUploaded(true);
            }}
            responseBody={dragDropExternalFileResponse}
            name="ThirdPartyFile"
          />
        ) : (
          <Alert variant="success" style={{ maxWidth: "fit-content" }}>
            File Successfully Uploaded
          </Alert>
        ))}
      <div className="flex flex-column flex-wrap justify-content-between align-items-start">
        <Form onSubmit={updateScoringSettings}>
          <Stack gap={3}>
            <div hidden={thirdPartyScoringToggle}>
              <h5>Hypopnea Criteria</h5>
              <Form.Check
                inline
                type="radio"
                label="Recommended (3%)"
                value="B"
                checked={hypopneaCriteria === "B"}
                name="hypopneaCriteria"
                id="hypopneaCriteriaB"
                onChange={(e) => setHypopneaCriteria(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                label="Acceptable (4%)"
                value="A"
                checked={hypopneaCriteria === "A"}
                name="hypopneaCriteria"
                id="hypopneaCriteriaA"
                onChange={(e) => setHypopneaCriteria(e.target.value)}
              />
            </div>
            <div>
              <h5>
                <TextWithTooltip
                  text="Signal Prefiltering (AASM)"
                  tooltip="Used to indicate whether the channels in the EDF have already been subject to AASM filtering such as high-pass and low-pass filtering. For example, AASM recommends that EEG signals are high pass filtered at 0.3 Hz and low pass filtered at 35 Hz."
                />
              </h5>
              <Form.Check
                inline
                type="radio"
                label="Yes"
                value="1"
                checked={prefilter === "1"}
                name="signalPrefiltering"
                id="signalPrefiltering1"
                onChange={(e) => setPrefilter(e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                label="No"
                value="0"
                checked={prefilter === "0"}
                name="signalPrefiltering"
                id="signalPrefiltering0"
                onChange={(e) => setPrefilter(e.target.value)}
              />
            </div>
            <div hidden={thirdPartyScoringToggle}>
              <h5>
                <TextWithTooltip
                  text="Lights Settings"
                  tooltip="One-based integer used to specify the start and end points for analysis. If you want to analyze the entire file enter 1 and -1. The values correspond to 30-second epochs."
                />
              </h5>
              <Row>
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Lights Off</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min="1"
                      value={lightsOffEpoch}
                      id="lightsOff"
                      onChange={(e) => setLightsOffEpoch(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <InputGroup.Text>Lights On</InputGroup.Text>
                    <Form.Control
                      type="number"
                      min="-1"
                      value={lightsOnEpoch}
                      id="lightsOn"
                      onChange={(e) => setLightsOnEpoch(e.target.value)}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </div>
            <div>
              <h5>
                <TextWithTooltip
                  text="Collection System (optional)"
                  tooltip='Specify "prodigy2" if you&apos;re processing a Cerebra Prodigy2 file, otherwise can be used to specify the name of the physical device used to collect the signal data.'
                />
              </h5>
              <Form.Control
                value={collectionSystem}
                id="collectionSystem"
                onChange={(e) => setCollectionSystem(e.target.value)}
              />
            </div>
            <Button
              variant="secondary"
              type="submit"
              disabled={thirdPartyScoringToggle && !thirdPartyScoringUploaded}
            >
              Complete
            </Button>
          </Stack>
        </Form>
      </div>
    </Stack>
  );
};

export default UpdateScoringSettings;
