import { useEffect, useState } from "react";
import { Button, Col, Form, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";

const SiteEdit = () => {
  const [currentSite, setCurrentSite] = useState();
  const { id } = useParams();

  const navigate = useNavigate();

  const updateSite = (event) => {
    event.preventDefault();

    apiRequest(`site/${id}`, {
      method: "PATCH",
      body: JSON.stringify({
        name: event.target.siteName.value,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to edit this site.");
        } else if (response.ok) {
          toast.success("Site Updated Successfully!");
          navigate(`/site/${id}`);
        } else {
          toast.error("An error occurred while updating this site.");
        }
      })
      .catch((error) => {
        toast.error("An error occurred while updating this site.");
        console.error(error);
      });
  };

  const getSite = (id) => {
    apiRequest(`site/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => setCurrentSite(data))
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    getSite(id);
  }, [id]);

  if (!currentSite) {
    return <PageLoading />;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Edit Site</h1>
      <Form onSubmit={updateSite}>
        <Row>
          <Col as="h3" xs="auto">
            Site Details
          </Col>
          <Col xs={4}>
            <Stack gap={3}>
              <Form.Group controlId="siteName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  defaultValue={currentSite.name}
                />
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <hr />
        <Button variant="secondary" type="submit">
          Submit
        </Button>
      </Form>
    </Stack>
  );
};

export default SiteEdit;
