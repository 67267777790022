import { useEffect, useState } from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  XAxis,
  YAxis,
} from "recharts";

import { apiRequest } from "src/apiRequest";
import PageLoading from "src/components/PageLoading";
import TextWithTooltip from "src/components/TextWithTooltip";

const ComparisonReport = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [comparison, setComparison] = useState(null);
  const [comparisonGroups, setComparisonGroups] = useState([]);

  const getReportData = () => {
    apiRequest(`comparison/${id}/orpreportsimple`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data.comparison) {
          setComparison(data.comparison);
          setComparisonGroups(data.comparison.comparison_groups);
        } else {
          toast.error("Error generating this report.");
          navigate("/comparisons");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Graph functions
  const customBarChartLabels = (props) => {
    const labels = [
      "Very Deep Sleep",
      "Deep Sleep",
      "Average Sleep",
      "Light Sleep",
      "",
      "Transtional State",
      "",
      "",
      "Drowsy Wake",
      "Full Wakefullness",
      "",
    ];
    const { x, y, width, index } = props;
    const radius = 10;
    const newX = x + width / 2;
    const newY = y - radius - 37;

    const shouldRotate = index !== 5;
    return (
      <text
        x={newX}
        y={newY}
        fill="#000"
        textAnchor="middle"
        dominantBaseline="middle"
        transform={shouldRotate ? `rotate(-90, ${newX}, ${newY})` : undefined}
        style={{ fontSize: "10px" }}
      >
        {labels[index]}
      </text>
    );
  };

  function renderNormalBarGraph() {
    const graphData = [
      {
        range: "000_025",
        value: 10,
        fill: "#0d1e71",
      },
      {
        range: "025_050",
        value: 25,
        fill: "#0d1e71",
      },
      {
        range: "050_075",
        value: 25,
        fill: "#3aa0f5",
      },
      {
        range: "075_100",
        value: 15,
        fill: "#3aa0f5",
      },
      {
        range: "100_125",
        value: 10,
        fill: "#3aa0f5",
      },
      {
        range: "125_150",
        value: 5,
        fill: "#3aa0f5",
      },
      {
        range: "150_175",
        value: 2,
        fill: "#3aa0f5",
      },
      {
        range: "175_200",
        value: 2,
        fill: "#3aa0f5",
      },
      {
        range: "200_225",
        value: 5,
        fill: "#3aa0f5",
      },
      {
        range: "225_250",
        value: 7,
        fill: "#FF0000",
      },
      {
        range: "bad",
        value: 0,
        fill: "#3AA0F5",
      },
    ];

    const customTicks = [
      { value: "000_025", label: "1" },
      { value: "025_050", label: "2" },
      { value: "050_075", label: "3" },
      { value: "075_100", label: "4" },
      { value: "100_125", label: "5" },
      { value: "125_150", label: "6" },
      { value: "150_175", label: "7" },
      { value: "175_200", label: "8" },
      { value: "200_225", label: "9" },
      { value: "225_250", label: "10" },
      { value: "bad", label: "n/a" },
    ];

    return (
      <div>
        <BarChart
          width={350}
          height={250}
          data={graphData}
          className="decile-bar-chart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            ticks={customTicks.map((tick) => tick.value)}
            tickFormatter={(tick) =>
              customTicks.find((t) => t.value === tick)?.label
            }
          />
          <YAxis
            label={{
              value: "% of sleep study",
              angle: -90,
              position: "insideBottomLeft",
            }}
            ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40]}
          />
          <Bar dataKey="value" fill={(data) => data.fill}>
            <LabelList
              dataKey="value"
              position="top"
              content={customBarChartLabels}
            />
          </Bar>
        </BarChart>
      </div>
    );
  }

  const getFillForRange = (range) => {
    if (range === "000_025" || range === "025_050") {
      return "#0d1e71";
    } else if (range === "225_250") {
      return "#FF0000";
    } else if (range === "bad") {
      return "#d3d3d3";
    } else {
      return "#3AA0F5";
    }
  };

  const CustomLegendOrpArchitecture = () => {
    const legendItems = [
      { value: "#FF0000", label: "ORP Fully Awake" },
      { value: "#0d1e71", label: "ORP Deep Sleep" },
      { value: "#d3d3d3", label: "Bad Data" },
    ];

    return (
      <ul className="bar-graph-legend">
        {legendItems.map((item, index) => (
          <li key={index} className="flex align-items-center">
            <svg width="5" height="4">
              <rect width="4" height="4" fill={item.value} />
            </svg>
            <span style={{ fontSize: "9px" }}>{item.label}</span>
          </li>
        ))}
      </ul>
    );
  };

  function renderDecileBarGraph(orpData) {
    const preparedData = Object.keys(
      orpData.orp?.summary.decile.total_study_percent,
    ).map((range) => ({
      range,
      value: orpData.orp.summary.decile.total_study_percent[range],
      fill: getFillForRange(range),
    }));

    preparedData.sort((a, b) => {
      if (a.range === "bad") return 1;
      if (b.range === "bad") return -1;
      return 0;
    });

    const customTicks = [
      { value: "000_025", label: "1" },
      { value: "025_050", label: "2" },
      { value: "050_075", label: "3" },
      { value: "075_100", label: "4" },
      { value: "100_125", label: "5" },
      { value: "125_150", label: "6" },
      { value: "150_175", label: "7" },
      { value: "175_200", label: "8" },
      { value: "200_225", label: "9" },
      { value: "225_250", label: "10" },
      { value: "bad", label: "n/a" },
    ];

    return (
      <div>
        <BarChart
          width={350}
          height={250}
          data={preparedData}
          className="decile-bar-chart"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="range"
            ticks={customTicks.map((tick) => tick.value)}
            tickFormatter={(tick) =>
              customTicks.find((t) => t.value === tick)?.label
            }
          />
          <YAxis
            label={{
              value: "% of sleep study",
              angle: -90,
              position: "insideBottomLeft",
            }}
            ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40]}
          />
          <Bar dataKey="value" fill={(data) => data.fill} />
          <Legend content={<CustomLegendOrpArchitecture />} />
        </BarChart>
      </div>
    );
  }

  useEffect(() => {
    getReportData();
  }, []);

  if (!comparison) {
    return <PageLoading />;
  }

  return (
    <Stack gap={4} className="body">
      <h1>Comparison ID: {id}</h1>
      <div className="data-card">
        <Row xs={1} lg={2} xxl={2}>
          <Col>
            <b>Name: </b>
            {comparison.name}
          </Col>
          <Col>
            <b>EDF Start Date: </b>
            {comparison.first_edf_date}
          </Col>
          <Col>
            <b>Description: </b>
            {comparison.description}
          </Col>
          <Col>
            <b>EDF End Date: </b>
            {comparison.last_edf_date}
          </Col>
          <Col />
          <Col>
            <b>Duration (days): </b>
            {comparison.duration_days}
          </Col>
        </Row>
      </div>
      {comparisonGroups.map((group, groupIndex) => (
        <Stack key={group.id} gap={4}>
          <h3>{group.name}</h3>
          {group.scoringruns.map((scoringRun) => (
            <div
              key={scoringRun.id}
              className={`data-card group-card group-card-${groupIndex}`}
            >
              <Row>
                <Col xs={2}>
                  <Stack gap={3}>
                    <div>
                      <span className="data-label">Scoring Run ID: </span>
                      {scoringRun.id}
                    </div>
                    <div>
                      <span className="data-label">EDF Date: </span>
                      <br />
                      {scoringRun.orpreport_data.edf_start_time}
                    </div>
                  </Stack>
                </Col>
                <Col xs={8} className="flex justify-content-around flex-wrap">
                  {renderNormalBarGraph()}
                  {renderDecileBarGraph(scoringRun.orpreport_data)}
                </Col>
                <Col xs={2}>
                  <Stack gap={3}>
                    <div>
                      <span className="data-label">
                        <TextWithTooltip
                          text="ORP Architecture:"
                          tooltip="A two digit representation, seperated by a comma, that provides a classification of sleep into 9 types.  The digits are derived from decile (bins) 1 & 2 representing very deep and deep sleep and decile (bin) 10 that represents fully awake."
                        />
                      </span>
                      {scoringRun.orpreport_data.orp_architecture}
                    </div>
                    <div>
                      <span className="data-label">Bin 1 & 2:</span>
                      {(
                        scoringRun.orpreport_data.orp?.summary?.decile
                          ?.total_study_percent["000_025"] +
                        scoringRun.orpreport_data.orp?.summary?.decile
                          ?.total_study_percent["025_050"]
                      )?.toFixed(1)}
                      %
                    </div>
                    <div>
                      <span className="data-label">Bin 10:</span>
                      {scoringRun.orpreport_data.orp?.summary?.decile?.total_study_percent[
                        "225_250"
                      ]?.toFixed(1)}
                      %
                    </div>
                  </Stack>
                </Col>
              </Row>
            </div>
          ))}
        </Stack>
      ))}
      <div className="data-card pagebreak">
        <div>
          <span className="data-label">Disclaimer:</span>
          <ul style={{ listStyleType: "none" }}>
            <li>
              The values presented here are the result of autoscoring and have
              not undergone manual review. These values should not be used in
              isolation to make a diagnosis.
            </li>
          </ul>
        </div>
        <div>
          <span className="data-label">For more information:</span>
          <br />
          <ol style={{ listStyleType: "none" }}>
            <li>
              <b>ORP Architecture: </b>Younes, M., et al. (2022). Sleep
              architecture based on sleep depth and propensity: Patterns in
              different demographics and sleep disorders and association with
              health outcomes. Sleep, 45(6), zsac059.{" "}
              <a href="https://doi.org/10.1093/sleep/zsac059">
                https://doi.org/10.1093/sleep/zsac059
              </a>
            </li>
            <li>
              <b>ORP Values: </b>Younes, M., et al., (2015). Odds Ratio Product
              of sleep EEG as a continuous measure of sleep state. Sleep, 38(4),
              641-654.{" "}
              <a href="https://doi.org/10.5665/sleep.4588">
                https://doi.org/10.5665/sleep.4588
              </a>
            </li>
            <li>
              <b>ORP-9: </b>Younes, M., & Hanly, P.J. (2016). Immediate
              postarousal sleep dynamics: An important determinant of sleep
              stability in obstructive sleep apnea. Journal of Applied
              Physiology, 120, 801-808.{" "}
              <a href="https://doi.org/10.1152/japplphysiol.00880.2015">
                https://doi.org/10.1152/japplphysiol.00880.2015
              </a>
            </li>
            <li>
              <b>Notes: </b>Younes, M. (2023). New insights and potential
              clinical implications of the odds ratio product. Frontiers of
              Neurology, 14, 1273623.{" "}
              <a href="https://doi.org/10.3389/fneur.2023.1273623">
                https://doi.org/10.3389/fneur.2023.1273623
              </a>
            </li>
            <li>
              <b>Hypoxic Burden:</b> Azarbarzin, A., Sands, S. A., Stone, K. L.,
              Taranto-Montemurro, L., Messineo, L., Terrill, P. I.,
              Ancoli-Israel, S., Ensrud, K., Purcell, S., White, D. P., Redline,
              S., & Wellman, A. (2019). The hypoxic burden of sleep apnoea
              predicts cardiovascular disease-related mortality: the
              Osteoporotic Fractures in Men Study and the Sleep Heart Health
              Study. European heart journal, 40(14), 1149–1157.
              <a href="https://doi.org/10.1093/eurheartj/ehy624">
                https://doi.org/10.1093/eurheartj/ehy624
              </a>
            </li>
          </ol>
        </div>
      </div>
    </Stack>
  );
};

export default ComparisonReport;
