import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Stack } from "react-bootstrap";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const CreateComparisonGroup = ({ comparisonId, setActiveKey }) => {
  const [groups, setGroups] = useState([{ name: "Pre" }, { name: "Post" }]);

  const createNewComparisonGroups = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page

    if (groups.length === 0) {
      toast.error("Please add at least one group before submitting.");
      return;
    }

    try {
      // Prepare POST requests for each group
      const requests = groups.map((group) =>
        apiRequest("comparisongroup/", {
          method: "POST",
          body: JSON.stringify({
            name: group.name,
            comparison_id: comparisonId,
          }),
        }),
      );

      // Execute all POST requests concurrently
      const responses = await Promise.all(requests);

      // Check for errors in the responses
      for (const response of responses) {
        if (!response.ok) {
          throw new Error(`Failed to create a group: ${response.statusText}`);
        }
      }

      toast.success("Groups created successfully!");

      // Clear the groups array after successful submission
      setGroups([]);

      // set state in wizard to next step
      setActiveKey("populateGroups");
    } catch (error) {
      console.error("Error creating groups:", error);
      toast.error("An error occurred while creating groups.");
    }
  };

  const addGroup = () => {
    setGroups([...groups, { name: "" }]);
  };

  const removeGroup = (indexToRemove) => {
    const updatedGroups = groups.filter((_, index) => index !== indexToRemove);
    setGroups(updatedGroups);
  };

  const updateGroups = (index, value) => {
    const updatedGroups = [...groups];
    updatedGroups[index].name = value;
    setGroups(updatedGroups);
  };

  return (
    <Stack gap={4} className="body">
      <h1>Create Groups</h1>
      <Form onSubmit={createNewComparisonGroups}>
        <Stack gap={3}>
          {groups.map((group, index) => {
            return (
              <div key={index} className="data-card col-5">
                <div className="float-right">
                  <Button
                    variant="danger"
                    id={`delete_${index}`}
                    onClick={() => removeGroup(index)}
                    size="small"
                  >
                    <FontAwesomeIcon icon="times" />
                  </Button>
                </div>
                <div className="pt-2">
                  <Form.Group controlId={`comparisonGroupName${index}`}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name={`comparisonGroupName${index}`}
                      value={group.name}
                      onChange={(e) => updateGroups(index, e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
            );
          })}
          <div>
            <Button onClick={addGroup}> Add New Group </Button>
          </div>
          <Button variant="secondary" type="submit">
            Submit
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export default CreateComparisonGroup;
