import { Button, Stack } from "react-bootstrap";

import ScoringRunTable from "src/components/ScoringRuns";

function Dashboard() {
  return (
    <Stack gap={4} className="body">
      <Stack direction="horizontal" gap={3} className="m-auto">
        <Button variant="secondary" href="/wizard" size="lg">
          New File Wizard
        </Button>
        <Button variant="secondary" href="/comparisonwizard" size="lg">
          New Comparison Wizard
        </Button>
      </Stack>
      <ScoringRunTable />
    </Stack>
  );
}

export default Dashboard;
