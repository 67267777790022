import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";

import { apiRequest } from "src/apiRequest";
import "src/assets/Wizard.scss";
import CreateComparison from "src/components/Comparisons/CreateComparison";
import CreateComparisonGroup from "src/components/Comparisons/CreateComparisonGroups";
import PopulateComparisonGroups from "src/components/Comparisons/PopulateComparisonGroups";

const ComparisonWizard = () => {
  const [activeKey, setActiveKey] = useState("createComparison");
  const [comparisonId, setComparisonId] = useState(null);
  const [comparison, setComparison] = useState(null);

  const getComparison = () => {
    if (comparisonId) {
      apiRequest(`comparison/${comparisonId}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((d) => {
          setComparison(d);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (comparisonId) setActiveKey("createGroups");
  }, [comparisonId]);

  useEffect(() => {
    if (activeKey === "populateGroups") getComparison();
  }, [activeKey]);

  return (
    <div className="d-flex flex-nowrap flex-grow-1">
      <Nav
        variant="pills"
        activeKey={activeKey}
        className="d-flex flex-column p-3"
        onSelect={(eventKey) => setActiveKey(eventKey)}
      >
        <Nav.Link eventKey="createComparison" disabled={comparisonId}>
          {comparisonId
            ? `Comparison ID: ${comparisonId}`
            : "Comparison Creation"}
        </Nav.Link>
        <Nav.Link
          eventKey="createGroups"
          disabled={activeKey !== "createGroups"}
        >
          Create Groups
        </Nav.Link>
        <Nav.Link
          eventKey="populateGroups"
          disabled={activeKey !== "populateGroups"}
        >
          Populate Groups
        </Nav.Link>
      </Nav>
      <div className="flex-grow-1">
        <div
          style={{
            display: activeKey === "createComparison" ? "block" : "none",
          }}
        >
          <CreateComparison setComparisonId={setComparisonId} />
        </div>
        <div
          style={{ display: activeKey === "createGroups" ? "block" : "none" }}
        >
          <CreateComparisonGroup
            comparisonId={comparisonId}
            setActiveKey={setActiveKey}
          />
        </div>
        <div
          style={{ display: activeKey === "populateGroups" ? "block" : "none" }}
        >
          <PopulateComparisonGroups comparison={comparison} />
        </div>
      </div>
    </div>
  );
};

export default ComparisonWizard;
