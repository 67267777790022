import { Button, Stack } from "react-bootstrap";

import DataTable from "src/components/DataTable";

const ComparisonsIndex = () => {
  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Description", accessor: "description" },
    {
      Header: "",
      render: (row) => (
        <Button variant="outline-primary" href={`/comparisonreport/${row.id}`}>
          Comparison Report
        </Button>
      ),
    },
  ];

  return (
    <Stack gap={4} className="body">
      <Button
        variant="secondary"
        href="/comparisonwizard"
        size="lg"
        className="mx-auto"
      >
        New Comparison Wizard
      </Button>
      <div className="flex align-items-center justify-content-between">
        <h1>Comparisons</h1>
      </div>
      <DataTable endpoint="comparison" columns={columns} itemsPerPage={10} />
    </Stack>
  );
};

export default ComparisonsIndex;
