import { Button, Form, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { apiRequest } from "src/apiRequest";

const CreateComparison = ({ setComparisonId }) => {
  const navigate = useNavigate();

  const createNewComparison = (event) => {
    event.preventDefault();

    const comparisonName = event.target.comparisonName.value; // Get the input value
    const comparisonDescription = event.target.comparisonDescription.value; // Get the input value

    apiRequest("comparison/", {
      method: "POST",
      body: JSON.stringify({
        name: comparisonName,
        description: comparisonDescription,
      }),
    })
      .then((response) => {
        if (response.status === 403) {
          toast.error("You don't have permission to create a comparison.");
        } else if (response.ok) {
          // Handle success
          toast.success("Comparison Successfully created!");
          return response.json();
        } else {
          // Handle other errors
          toast.error("An error occurred while creating the comparison.");
        }
      })
      .then((data) => {
        if (setComparisonId) {
          setComparisonId(data.id);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error("An error occured while creating the comparison");
        console.error(error);
      });
  };

  return (
    <Stack gap={4} className="body">
      <h1>Create New Comparison</h1>
      <Form onSubmit={createNewComparison}>
        <Stack gap={3}>
          <h5>Comparison Details</h5>
          <Form.Group controlId="comparisonName">
            <Form.Label>Name</Form.Label>
            <Form.Control name="comparisonName" />
          </Form.Group>
          <Form.Group controlId="comparisonDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control name="comparisonDescription" as="textarea" />
          </Form.Group>
          <Button variant="secondary" type="submit">
            Submit
          </Button>
        </Stack>
      </Form>
    </Stack>
  );
};

export default CreateComparison;
